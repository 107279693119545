
#traceForm {

    .kp-trace-field {
        display: flex;
        align-items: flex-end;
        margin-top: var(--hds-spacing-12);

        .hds-input-group {
            width: 100%;
            margin-right: var(--hds-spacing-12)
        }

        button {
            text-wrap: nowrap;
        }
    }
    
    &.white label {
        color:white;
    }
    
}
